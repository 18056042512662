import React from 'react';
import Learner from '../../../../../api/models/Learner';
import { useState } from 'react';

export default function LearnerForm() {

    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [dateOfBirth, setDateOfBirth] = useState('2024-01-01');
    const [gender, setGender] = useState('male');
    const [grade, setGrade] = useState(1);
    const [currYearOfStudy, setCurrYearOfStudy] = useState('2024');
    const [currentLevel, setCurrentLevel] = useState('secondary');
    const [institution, setInstitution] = useState('high-school');
    
    async function post(event) {
        event.preventDefault();
        const Learner = Learnercreate({
            firstName: firstName, 
            middleName: middleName, 
            lastName: lastName, 
            dateOfBirth: dateOfBirth,
            gender: gender, 
            grade: grade, 
            currYearOfStudy: currYearOfStudy, 
            currentLevel: currentLevel, 
            institution: institution
        }).fetch();
        
        console.log('Learner->', Learner);
    }

    return (

        <div style={{ margin: 'auto', width: '100%', border: '1px solid #b4b4cf', borderRadius: 5, padding: 5, boxSizing: "border-box" }}>

            <div style={{ padding: '5px', margin: '5px', color: 'white', backgroundColor: 'rgb(162 162 194)', textAlign: 'center', fontWeight: 400, fontWeight: 'bold' }}>
                Learner
            </div>


            <form style={{ marginTop: 0, width: '100%', display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box', paddingLeft: '5px', paddingRight: '5px' }}>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>First Name</label>
                    <input onChange={(e) => setFirstName(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} name='firstName' ></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Middle Name</label>
                    <input onChange={(e) => setMiddleName(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} name="middleName"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Last/Sur Name</label>
                    <input onChange={(e) => setLastName(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} name="lastName"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }} >Date of Birth</label>
                    <input value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} type="date" name="dateOfBirth" placeholder="1"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Gender</label>
                    <select value={gender} onChange={(e) => setGender(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }}>
                        <option defaultValue value="male" >Male</option>
                        <option value="female" >Female</option>
                        <option value="not-say" >Rather not Say</option>
                    </select>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Current Level</label>
                    <select value={currentLevel} onChange={(e) => setCurrentLevel(e.target.value)} style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }}>
                        <option defaultValue value="primary" >Lower/Primary Class</option>
                        <option value="secondary">Secondary/Upper Class</option>
                        <option value="college">College</option>
                        <option value="university">University</option>
                        <option value="other" >Other</option>
                    </select>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Institution/ School</label>
                    <select onChange={(e) => setInstitution(e.target.value)} style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }}>
                        <option defaultValue value="high-school">Test High School</option>
                        <option>Test 2 School</option>
                        <option>Nairobi University</option>
                        <option>Technical University of Mombasa</option>
                    </select>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Grade/Class</label>
                    <input onChange={(e) => setGrade(e.target.value)} style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} type="number" placeholder="1"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    {/*  Can be used for historical data entry */}
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }} >Current Year of study</label>
                    <input onChange={(e) => setCurrYearOfStudy(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} type="number" placeholder="1"></input>
                </div>
                <div style={{ flexFlow: 'column', margin: 'auto', padding: '5px', boxSizing: 'border-box', display: 'flex', width: '50%' }}>
                    <button onClick={(e) => { return post(e) }} style={{ fontWeight: 300, width: '100%' }}>Save</button>
                </div>
            </form>
        </div>
    )
}