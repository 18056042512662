/**
 * Learner.js
 *
 * @description :: A model definition represents a database table/collection.
 * @docs        :: https://sailsjs.com/docs/concepts/models-and-orm/models
 */

module.exports = {

  attributes: {

    firstName: {
      type: 'string',
      required: true,
      unique: false,
      maxLength: 240,
      example: ''
    },
    middleName: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },
    lastName: {
      type: 'string',
      required: true,
      unique: false,
      maxLength: 240,
      example: ''
    },
    emailAddress:{
      type:'string',
      required: false,
      isEmail: true
    },
    phoneNumber:{
      type:'string',
      required: false,
      maxLength: 20
    },
    dateOfBirth: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },
    gender: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },
    currYearOfStudy: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },
    currentLevel: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },
    institution: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    }
  },
  multitenant: true
};

