import React from 'react'
import Sponsor from '../../../../../api/models/Sponsor';
import { useState } from 'react';

export default function SponsorForm() {

    const [category, setCategory] = useState('individual');
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [dateOfBirth, setDateOfBirth] = useState('2024-01-03');
    const [gender, setGender] = useState('male');
    const [emailAddress, setEmailAddress] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [businessName, setBusinessName] = useState();

    async function post(event) {
        event.preventDefault();
        const sponsor = Sponsor.constructor(category,firstName, middleName,
            lastName,dateOfBirth,gender,emailAddress,phoneNumber,businessName);
        console.log('sponsor->', sponsor);
    }


    return (
        
        <div style={{ margin: 'auto', width: '100%', border: '1px solid #b4b4cf', borderRadius: 5, padding: 5, boxSizing: "border-box" }}>

            <div style={{ padding: '5px', margin: '5px', color: 'white', backgroundColor: 'rgb(162 162 194)', textAlign: 'center', fontWeight: 400, fontWeight: 'bold' }}>Sponsor</div>
            <form style={{ marginTop: 0, width: '100%', display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box', paddingLeft: '5px', paddingRight: '5px' }}>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Category</label>
                    <select value={category} onChange={(e) => setCategory(e.target.options.value)}   style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }}>
                        <option defaultValue id="individual" value="individual">Individual</option>
                        <option id="institution" value="institution">Institution</option>
                        <option id="business" value="business">Business (CSR function)</option>
                    </select>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>First Name</label>
                    <input onChange={(e) => setFirstName(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} name="firstName"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Middle Name</label>
                    <input onChange={(e) => setMiddleName(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} name="middleName"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Last/Sur Name</label>
                    <input onChange={(e) => setLastName(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} name="lastName"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }} >Date of Birth</label>
                    <input value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} type="date" name="dateOfBirth"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Gender</label>
                    <select value={gender} onChange={(e) => setGender(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%'}}  name="gender" >
                        <option defaultValue value="male" >Male</option>
                        <option value="female" >Female</option>
                        <option value="not-say" >Rather not Say</option>
                    </select>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Email Address</label>
                    <input onChange={(e) => setEmailAddress(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }}  name="emailAddress"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Phone Number</label>
                    <input onChange={(e) => setPhoneNumber(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }}  name="phoneNumber"></input>
                </div>

                <div style={{ marginBottom: '1px', marginTop: '1px', padding: '5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width: '100%' }}>
                    <label style={{ border: '1px solid rgba(199, 209, 255, 0.192)', fontWeight: 300, width: '50%' }}>Business Name</label>
                    <input onChange={(e) => setBusinessName(e.target.value)}  style={{ border: '1px solid rgba(199, 209, 255, 0.192)', width: '50%' }} name="businessName"></input>
                </div>

                <div style={{ flexFlow: 'column', margin: 'auto', padding: '5px', boxSizing: 'border-box', display: 'flex', width: '50%' }}>
                    <button onClick={(e) => { return post(e) }} style={{ fontWeight: 300, width: '100%' }}>Save</button>
                </div>
            </form>

        </div>
    )
}