import React, {useEffect} from 'react';
import './Login.css';
import { useParams } from 'react-router';
import useAxios from '../../hooks/axios';
import { useKeycloak } from '@react-keycloak/web';
import { useAuth } from '../../hooks/auth-hook';


export default function Login(props){

    const params = useParams();
    const { axios, token } = useAxios.useAxios({});
    const switchKeycloakContext = props.switchKeycloakContext;
    const { keycloak, initialized } = useKeycloak(true);
    const auth = useAuth();

    useEffect(()=>{
        
    },[params.realmId])


    async function validateRealm(realmId){

        try{

            const tenantUrl = `${process.env.API_BASE_URL}/tenant/${realmId}`;
            await axios.get(tenantUrl)
            .then((response) => {
                console.log(response);
                const teants = response.data;
                if(teants.length===1){
                    try{
                        sessionStorage['realmId'] = realmId;
                        keycloak.realm = realmId;
                        auth.login({
                            scope: 'openid',
                            redirectUri: `${process.env.BASE_URL}/dashboard`,
                            prompt: 'login',
                            action: 'tologin',
                            maxAge: '100000',
                            loginHint: teants[0].emailAddress,
                            idpHint: 'idpHint',
                            cordovaOptions: null
                        });
                        //switchKeycloakContext(realmId,'login-required');
                    }catch(Error){
                        console.log(Error);
                    }
                }
            });

        }catch(Error){
            console.log(Error);
        }

    }

    return (

        <div className="loginPage">
            <div className="loginForm" >
                <button className="loginBtn" onClick={()=>validateRealm(params.realmId)}>Log In</button>
            </div>
        </div>

    );
}