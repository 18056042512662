import React, { useState, useEffect } from 'react';
import './PageLoader.css';
import logo from '../../../../assets/images/WezeshaLogo.png'

export default function PageLoader() {

    const [loaderInterval, setLoaderInterval] = useState();
    const [maxCycles, setMaxCycles] = useState(100);
    const [loaderIntervals, setLoaderIntervals] = useState([]);

    useEffect(() => {

        if (!cancelInterval) {
            animate();
        } else {
            clearInterval(loaderInterval);
        }

        return () => {
            loaderIntervals.forEach((loaderInterval__)=>{
                try{
                    clearInterval(loaderInterval__);
                }catch(Error){
                    console.error(Error);
                }
            });
        }
    }, []);

    let start = 0;
    let cycleyCount = 0;
    let cancelInterval = false;
    let loaderInterval_ = loaderInterval;

    function decorate() {
        
        if (cancelInterval) {
            clearInterval(loaderInterval_);
            loaderIntervals.forEach((loaderInterval__)=>{
                try{
                    clearInterval(loaderInterval__);
                }catch(Error){
                    console.error(Error);
                }
            });
            return;
        }

        const loadingSpinner = document.getElementById('loadingSpinner');
        const highlightedEl = document.getElementById('highlightedEl');

        if (loadingSpinner) {
            let len = 0;
            if (highlightedEl) {
                const dagorated = highlightedEl.innerHTML;
                let firstBrace_idx = loadingSpinner.innerHTML.indexOf('<');
                firstBrace_idx = firstBrace_idx === 0 ? 0 : firstBrace_idx;
                let firstPart = loadingSpinner.innerHTML.substring(0, firstBrace_idx);
                firstPart = firstPart === '<' ? '' : firstPart;
                const secondBrace_idx = loadingSpinner.innerHTML.lastIndexOf('</span>') + 7;
                len = loadingSpinner.innerHTML.length;
                const secondPart = loadingSpinner.innerHTML.substring(secondBrace_idx, len);
                const output = `${firstPart}${dagorated}${secondPart}`;
                loadingSpinner.removeChild(highlightedEl);
                loadingSpinner.innerHTML = output;
            }
            const end = start + 1;
            len = loadingSpinner.innerHTML.length;
            const firstPart = loadingSpinner.innerHTML.substring(0, start);
            const toDagorate = loadingSpinner.innerHTML.substring(start, end);
            const secondPart = loadingSpinner.innerHTML.substring(end, len);
            const output = `${firstPart}<span id='highlightedEl' class='highlight'>${toDagorate}</span>${secondPart}`;
            loadingSpinner.innerHTML = output;
            if (start >= len) {
                cycleyCount = cycleyCount + 1;
                start = 0;
                if (cycleyCount >= maxCycles) {
                    cancelInterval = true;
                    cycleyCount = 0;
                }
            } else {
                start = start + 1;
            }
        }else{//Cancel the timeout
            clearInterval(loaderInterval_);
            loaderIntervals.forEach((loaderInterval__)=>{
                try{
                    clearInterval(loaderInterval__);
                }catch(Error){
                    console.error(Error);
                }
            });
        }
    }


    async function animate() {
        const interval_ = () => {
            decorate();
        };
        loaderInterval_ = setInterval(interval_, 500);
        setLoaderInterval(loaderInterval_);
        loaderIntervals.push(loaderInterval_);
        setLoaderIntervals(loaderIntervals);
    }

    return (
        <div className="pageLoader">
            <div className="centeredLoader">
                <div className="centeredLogo"><img src={logo} height={30} /></div>
                <div id="loadingSpinner" className="loadingSpinner">Loading...</div>
            </div>
        </div>
    )
}