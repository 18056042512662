import React from 'react';
//import styles from './index.module.css';
import StudentForm from '../student/student-form/student-form';
import Institution from '../institution/institution-form/institution-form';
import SponsorForm from '../sponsor/sponsor-form/sponsor-form'
//import { useKeycloak } from '@react-keycloak/web';
import { useAuth } from '../../hooks/auth-hook';
import PageLoader from '../loaders/PageLoader';

export default function Index() {

    const auth = useAuth();

    if (!auth?.initialized) {
        return <PageLoader/>;
    }
    if (!auth?.isAuthenticated) {
        auth.login();
    }

    return (
        <div style={{
            fontWeight: 100,
            fontFamily: 'system-ui',
            fontSize: '1.1rem',
            backgroundColor: 'rgba(250, 248, 248, 0.527)',
            margin: '5px',
            padding: '5px'}} 
            
            className="indexRootComponent">
            <SponsorForm/><br/>
            <StudentForm/><br/>
            <Institution/>
        </div>
    )
}