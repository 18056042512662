import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminDashboard from './components/dashboards/admin/AdminDashboard';
import Index from './components/index/index';
import PrivateRoute from './protected-routes/PrivateRoute';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloakInstance1 from './config/keycloak';
import Sigup from './components/signup/signup';
import '../styles/bootstrap.min.css';
import Login from './components/login/Login';
import Keycloak from 'keycloak-js';

const baseUrl = process.env.REACT_APP_BASE_URL || '';

// Define the eventLogger function
const eventLogger = (event, error) => {
    console.log('onKeycloakEvent', event, error);
};

// Define the tokenLogger function
const tokenLogger = (tokens) => {
    //console.log('onKeycloakTokens', tokens);
};


export default function App() {

    const [keycloak, setKeycloak] = useState(keycloakInstance1);

    const switchKeycloakContext = (realmId, onLoad_) => {

        if (sessionStorage['currentRealmId'] !== realmId) {
            const keycloakConfig = {
                url: process.env.CORE_KC_BASE_URL,
                realm: realmId || sessionStorage['realmId'] || process.env.CORE_KC_REALM_NAME,
                clientId: process.env.KC_WEB_CLIENT_ID,
                pkceMethod: process.env.KC_WEB_CLIENT_PKCE_METHOD,
                KeycloakResponseType: process.env.KC_RESPONSE_TYPE,
                onLoad: onLoad_ || process.env.KC_ONLOAD,
                checkLoginIframe: process.env.KC_CHECK_LOGIN_IFRAME || false
            };
            setKeycloak(new Keycloak(keycloakConfig));
            sessionStorage['currentRealmId'] = realmId;
        }

    };

    return (
        <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger} >
            <React.StrictMode>
                <Router basename={baseUrl}>
                    <Routes>
                        <Route path="/" element={<Index switchKeycloakContext={switchKeycloakContext} />} />
                        <Route path="/login/:realmId" element={<Login switchKeycloakContext={switchKeycloakContext} />} />
                        <Route path="/signup" element={<Sigup switchKeycloakContext={switchKeycloakContext} />} />
                        <Route path="/dashboard" element={<PrivateRoute switchKeycloakContext={switchKeycloakContext} ><AdminDashboard switchKeycloakContext={switchKeycloakContext} /></PrivateRoute>} />
                    </Routes>
                </Router>
            </React.StrictMode>
        </ReactKeycloakProvider>
    )
}

