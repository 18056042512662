/**
 * Sponsor.js
 *
 * @description :: A model definition represents a database table/collection.
 * @docs        :: https://sailsjs.com/docs/concepts/models-and-orm/models
 */

module.exports = {


  attributes: {

    category: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },

    firstName: {
      type: 'string',
      required: true,
      unique: false,
      maxLength: 240,
      example: ''
    },

    middleName: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },

    lastName: {
      type: 'string',
      required: true,
      unique: false,
      maxLength: 240,
      example: ''
    },

    dateOfBirth: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },
    gender: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },
    emailAddress: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: '',
      isEmail: true
    },
    phoneNumber: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    }

  },
  multitenant: true
};

