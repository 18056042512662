import React from "react";
import ReactLoading from "react-loading";
import './SignupProgressLoader.css';
import { useState, useEffect } from 'react';


export default function SignupProgressLoader(props) {
    const possibletypes = ["spinningBubbles", "balls", "bars", "bubbles", "cubes", "cylon", "spin", "spokes"]
    const loader = props.loader;
    const message = props.message;
    const display = props.display || false;
    const stopCallerInterval = props.stopCallerInterval;
    const showLoadSpinner = props.showLoadSpinner;
    const [completeProcesses, setCompleteProcesses] = useState([]);
    const loginLogout = props.loginLogout;
    const setDisplayLoader = props.setDisplayLoader;
    const displayLoader = props.displayLoader;
    const auth = props.auth;
    const submitInProgress = props.submitInProgress;
    const setSetupInProgress = props.setSetupInProgress;
    const setupInProgress = props.setupInProgress;
    const regIsComplete = props.regIsComplete;
    const setRegIsComplete = props.setRegIsComplete;
    const [hasError, setHasError] = useState(false);

    const clearForm = props.clearForm;
    

    useEffect(() => {

        const loadingBay = document.getElementById('loadingMsg');
        if (loadingBay) {
            const div = document.createElement('div');
            let completes = [];
            message.forEach((msg) => {

                let child = document.createElement('div');

                if (msg.indexOf('#') > -1) {
                    const status = msg.split('#')[0];
                    const processId = msg.split('#')[1];
                    if (processId.indexOf('_FINISH') > -1) {
                        
                        if (!completes[processId]) {
                            completes.push(processId);
                            setCompleteProcesses(completes);
                        }
                    }
                    
                    const itemNodeId = `${processId}${status}`;
                    const mesprocessDescription = msg.split('#')[2];
                    if (mesprocessDescription.indexOf('Error') > -1 || 
                        mesprocessDescription.indexOf('already exists') > -1
                        ) {
                        setHasError(true);
                        setSetupInProgress(false);
                    }
                    
                    if (!document.getElementById(itemNodeId)) {
                        child.id = itemNodeId;
                        child.innerHTML = mesprocessDescription;
                        div.appendChild(child);
                    }

                    if (mesprocessDescription.indexOf('Finished updating tenant.') > -1 ) {
                        
                        setRegIsComplete(true);
                        setDisplayLoader(false);
                        //stopCallerInterval();
                    }

                } else {
                    if (msg.indexOf('_FINISH') > -1) {
                        let completes = completeProcesses;
                        console.log('   <---> completes[processId]', completes[processId]);
                        if (!completes[processId]) {
                            completes.push(processId);
                            setCompleteProcesses(completes);
                        }
                    }
                    child.innerHTML = msg;
                    div.appendChild(child);

                }
            });

            if (div.childElementCount > 0)
                loadingBay.appendChild(div);

                const potentialLoginBtns = document.getElementsByClassName('postRegLogin');
                if(potentialLoginBtns){
                    for (let i = 0; i < potentialLoginBtns.length; i++) {
                        potentialLoginBtns[i].addEventListener('click' , ()=>{loginLogout_()});
                        setRegIsComplete(true);
                    }
                }

        }


        if (completeProcesses.length >= 6 || regIsComplete) {
            setCompleteProcesses([]);
            setRegIsComplete(true);
            setSetupInProgress(false);//Finished
        }

  
        return () => {
        };
    }, [message]);

    function loginLogout_(event) {
        if(event)
            event.preventDefault();
        if (!hasError) {
            loginLogout();
        } else {
            setDisplayLoader(false);
        }
    }

    function doResetForm_(event){
        event.preventDefault();
        clearForm();
        setDisplayLoader(false);
    }

    if( !setupInProgress ) {//If finished flag was received
        stopCallerInterval();
    }

    return (
        <div>
            {display &&
                <div className="centeredLayover">
                    <div className="loadingEl">
                        { setupInProgress && !hasError  && <ReactLoading className="loaderEl" type={`${loader || 'spin'}`} color="rgb(191 217 216)" height={100} width={50} />}
                        <div id="loadingMsg" className="loadingMsg">

                        </div>
                        { !setupInProgress  &&
                            <>
                                <div style={{ flexFlow: 'column', padding: '5px', boxSizing: 'border-box', width: '30%' }}>
                                    <button className='proceedLoginBtn' onClick={(e) => { return loginLogout_(e) }} > {!hasError ? (!auth.isAuthenticated ? 'Proceed to Login': 'Log out') : 'Close'} </button>
                                </div>

                                <div style={{ flexFlow: 'column', padding: '5px', boxSizing: 'border-box', width: '30%' }}>
                                    <button className='cancelBtn'  onClick={(e) => { return doResetForm_(e) }} >Close</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }
        </div>

    );
}
