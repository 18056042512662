/**
 * Institution.js
 *
 * @description :: A model definition represents a database table/collection.
 * @docs        :: https://sailsjs.com/docs/concepts/models-and-orm/models
 */

module.exports = {

  attributes: {

    /*
    _constructor: (institutionName, institutionType, country, yearOfEstablishement) => {
      this.institutionName = institutionName; 
      this.institutionType = institutionType; 
      this.country = country; 
      this.yearOfEstablishement = yearOfEstablishement;
      return this;
    },*/

    institution: {
      type: 'string',
      required: true,
      unique: false,
      maxLength: 240,
      example: ''
    },

    institutionType: {
      type: 'string',
      required: true,
      unique: false,
      maxLength: 240,
      example: ''
    },

    country: {
      type: 'string',
      required: false,
      unique: false,
      maxLength: 240,
      example: ''
    },

    yearOfEstablishement: {
      type: 'string',
      required: true,
      unique: false,
      maxLength: 240,
      example: ''
    },


  },
  multitenant: true
};

