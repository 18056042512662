import React from 'react';
import {useAuth} from '../hooks/auth-hook';
import PageLoader from '../components/loaders/PageLoader';

const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  if (!auth?.initialized) {
    return <PageLoader/>;;
  }
  if (!auth?.isAuthenticated) {
      auth.login();
  }

  return children;
};

export default PrivateRoute;
