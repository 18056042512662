import React, { useState, useEffect } from 'react';
import useAxios from '../../hooks/axios';
import PageLoader from '../loaders/PageLoader';
import { useAuth } from '../../hooks/auth-hook';
import SignupProgressLoader from '../loaders/SignupProgressLoader';
import MD5 from 'crypto-js/md5';
import { useKeycloak } from '@react-keycloak/web'

import './signup.css';

export default function Sigup(props) {

    const { keycloak } = useKeycloak(true);
    const auth = useAuth();
    const switchKeycloakContext = props.switchKeycloakContext;
    const { axios, initialized, token } = useAxios.useAxios({});
    const [emailAddress, setEmailAddress] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [userType, setUserType] = useState();
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [otp, setOtp] = useState();
    const [otpBtnDisabled, setOtpBtnDisabled] = useState(false);
    const [signupButtonIsDisabled, setSignupButtonIsDisabled] = useState(true);
    const [otpIsValid, setOtpIsValid] = useState(false);
    const [otpNounce, setOtpNounce] = useState('');
    const [countDownSeconds, setCountDownSeconds] = useState(0);
    const [countDownInterval, setCountDownInterval] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [generalNotification, setGeneralNotification] = useState('');

    const [displayLoader, setDisplayLoader] = useState(false);
    const [loaderMsg, setLoaderMsg] = useState([]);
    const [progressTimer, setProgressTimer] = useState();
    const [loaderInterval, setLoaderInterval] = useState();
    const [regIsComplete, setRegIsComplete] = useState(false);
    const [setupInProgress, setSetupInProgress] = useState(false);
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [pollingInProgress, setPollingInProgress] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [institutionName, setInstitutionName] = useState();
    const [selectedUserType, setSelectedUserType] = useState();

    const [enforceOTP, setEnforceOTP] = useState(true);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };


    useEffect(() => {

        const getContextProps = async function () {

            try {
                const response = await axios.get(`${contextPropsUrl}/${tenantId || -1}`)///${businessName}
                    .then((response) => {
                        let respData = DOMPurify.sanitize(JSON.stringify(response.data));
                        //console.log('>> respData', respData);
                        const data_ = JSON.parse(respData);
                        return respData;
                    });
                return response;
            } catch (Error) {
                console.log(Error);
            }

        };

        if (initialized) {
            getContextProps().then((response) => {
                const respData = JSON.parse(response);
                sessionStorage['realmId'] = respData.realmId;
                console.log(` ::::: respData.realmId -> ${respData.realmId} `);
            });
        };

        if (!enforceOTP) {
            setSignupButtonIsDisabled(false);
        }

        return () => {
            clearInterval(countDownInterval);
        }

    }, [initialized]);



    async function pollProgress(isFinal) {//Because we could not make sockts work.. Reminder 3 days were wasted before arriving to this solution. Never waste time again if there is a ready solution!!!

        if (!pollingInProgress) {
            if (!displayLoader || !(isFinal || false)) {

                setPollingInProgress(true);

                const registrationData = {
                    institutionName: institutionName,
                    emailAddress: emailAddress
                }

                const institutionNameAndEmailAddress = `${registrationData.institutionName}${registrationData.emailAddress}`;
                const institutionNameEmailHash = MD5(institutionNameAndEmailAddress).toString();
                const accountProgressSetupUpdateUrl = `${process.env.API_BASE_URL}/registration/progress/${institutionNameEmailHash}`;


                await axios.get(accountProgressSetupUpdateUrl)
                    .then((response) => {
                        setPollingInProgress(false);//release for next interval
                        const updateStream = response.data;
                        if (updateStream && updateStream.length > 0) {
                            const newMsgs = [];
                            updateStream.forEach((update) => {
                                newMsgs.push(`${update.status}#${update.processId}#${update.processDescription}`);
                            });
                            setLoaderMsg(newMsgs);
                        } else {
                        }
                    });
            } else {
                console.log('Loader has been disabled, won\'t make API calls.');
            }
        } else {
            //stopCallerInterval();
            console.log('Wait to poll...');
        }

    }


    async function signup() {
        try {

            const signupForm = {
                emailAddress: emailAddress,
                phoneNumber: phoneNumber,
                userType: userType,
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                otp: otp,
                otpNounce: otpNounce,
                institutionName: institutionName
            }

            const signupUrl = `${process.env.API_BASE_URL}/signup`;

            if (selectedUserType == 'An Institution') {

                setIsDisabled(true);//Disable the Submit button
                setRegIsComplete(false);
                setSetupInProgress(true);
                stopCallerInterval();//Stop any prior loader interval
                setPollingInProgress(false);
                const setupIdentity = institutionName ? 'the institution <b><i>' + institutionName + '</i></b>.' : 'your personal account, <b><i>' + firstName + ' ' + middleName + ' ' + lastName + '</b></i>';
                setLoaderMsg([`Setting up ${setupIdentity} Please wait...`]);
                setDisplayLoader(true);
                const progressTimer_ = () => {
                    const isFinal = false;
                    pollProgress(isFinal);
                };
                setLoaderInterval(setInterval(progressTimer_, 1000));
                setProgressTimer(progressTimer_);
                progressTimer_();
                setSubmitInProgress(true);

            } else {

                setShowLoader(true);

            }

            await axios.post(signupUrl, signupForm)
                .then((response) => {
                    console.log(response);
                    setShowLoader(false);

                    sessionStorage['realmId'] = response.data.realmId;
                    //keycloak.realm =  response.data.realmId;
                    switchKeycloakContext(response.data.realmId, 'login-required');
                    try {
                        //updateRegistrationData(data_);//Cascade through redux and UI

                        //sessionStorage['realmId'] = data_.updatedRegistration.realmId;
                        //switchKeycloakContext(data_.updatedRegistration.realmId, 'login-required');


                    } catch (Error) {
                        console.log(Error);
                    }


                });

        } catch (Error) {
            console.error(Error);
        }
    }

    let counter_ = 60;
    let countDownInterval_ = countDownInterval;

    function updateCounter() {
        try {
            counter_ = counter_ - 1;
            setCountDownSeconds(counter_);
            if (counter_ === 0) {
                clearInterval(countDownInterval_);
                const otpLink = document.getElementById('otpLink');
                otpLink.className = 'otpLink';
                otpLink.addEventListener('click', () => { generateOtp });
                const sendAgain = document.getElementById('sendAgain');
                sendAgain.className = 'sendAgain';
            }
        } catch (Error) {
            console.error(Error);
        }
    }

    async function generateOtp() {
        try {
            const payload = {
                phoneNumber: phoneNumber,
                emailAddress: emailAddress
            }
            const allFieldsValid = await validateAllFields();
            if (otpBtnDisabled === false && allFieldsValid === true && countDownSeconds <= 0) {

                const otpUrl = `${process.env.API_BASE_URL}/otp/generate`;
                setShowLoader(true);
                await axios.post(otpUrl, payload)
                    .then((response) => {
                        setOtpNounce(response.data.otpNounce);
                        const interval_ = () => {
                            updateCounter();
                        }
                        countDownInterval_ = setInterval(interval_, 1000, countDownSeconds);
                        setCountDownInterval(countDownInterval_);
                        setCountDownSeconds(60);
                        const otpLink = document.getElementById('otpLink');
                        otpLink.className = 'disabledOTPLink';
                        otpLink.onclick = () => { };
                        otpLink.removeEventListener('click', generateOtp);
                        otpLink.removeEventListener('click', () => { console.log('Wait for countdown') });
                        const sendAgain = document.getElementById('sendAgain');
                        sendAgain.className = 'sendAgainFocused';
                        setShowLoader(false);
                    });
            } else {
                // 'OTP requests Disabled. Wait timeout to expire'
            }
        } catch (Error) {
            console.log(Error);
        }
    }


    async function validateOtp(otp) {

        setOtp(otp);// Not necessary???

        if (otp.length >= 4) {
            try {
                const payload = {
                    otpNounce: otpNounce,
                    emailAddress: emailAddress,
                    phoneNumber: phoneNumber,
                    otp: otp
                }
                const otpUrl = `${process.env.API_BASE_URL}/otp/validate/${emailAddress}/${phoneNumber}/${otpNounce}/${otp}`;
                await axios.get(otpUrl).then((response) => {
                    const otpValidateResponse = response.data;
                    const generalNotofication = document.getElementById('generalNotofication');
                    if (otpValidateResponse.exists === true) {
                        if (otpValidateResponse.valid === true) {
                            setSignupButtonIsDisabled(false);
                            generalNotofication.style.display = 'block';
                            generalNotofication.style.color = 'green';
                            generalNotofication.innerHTML = 'Otp valid. Click \'Sign Up\' to proceed.';
                            const fn_ = () => {
                                try {
                                    document.getElementById('generalNotofication').innerHTML = '';
                                    document.getElementById('generalNotofication').style.color = 'red';
                                    document.getElementById('generalNotofication').style.display = 'none';
                                } catch (Error) {
                                    console.error(Error);
                                }
                            }
                            setTimeout(fn_, 5000);
                        } else {
                            if (otp.length >= 5)
                                generalNotofication.innerHTML = 'OTP has already been used. Generate another';
                            //Exists, but invalid
                            setSignupButtonIsDisabled(true);

                            generalNotofication.style.display = 'block';
                            generalNotofication.style.color = 'red';
                            generalNotofication.innerHTML = 'This OTP has already been used. Please generate another one.';

                        }
                    } else {
                        if (otp.length >= 5)
                            generalNotofication.innerHTML = 'OTP invalid. Try again';
                        //doesn't exist at all
                        setSignupButtonIsDisabled(true);
                    }
                });
            } catch (Error) {
                console.log(Error);
            }
        }
    }

    async function validateAllFields() {

        const fieldErrors = document.getElementsByClassName('fieldError');
        let allFieldsAreValid = true;
        if (fieldErrors) {
            for (let i = 0; i < fieldErrors.length; i++) {
                const fieldError = fieldErrors[i];
                const actualField = fieldError.nextSibling;
                const fieldVal = actualField.value.trim();
                fieldError.innerHTML = actualField.required && fieldVal === '' ? '*' : '&nbsp;&nbsp;';

                if (actualField.required && actualField.type === 'email') {
                    if (!validateEmail(fieldVal)) {
                        fieldError.innerHTML = '*';
                        allFieldsAreValid = false;
                    }
                }
                if (actualField.required && actualField.type === 'number') {
                    if (fieldVal.length < actualField.getAttribute('minlength')) {
                        fieldError.innerHTML = '*';
                        allFieldsAreValid = false;
                    }
                }
                if (actualField.required && fieldVal === '') {
                    allFieldsAreValid = false;
                }
            }
        }

        const generalNotofication = document.getElementById('generalNotofication');

        if (allFieldsAreValid) {
            generalNotofication.style.display = 'none';
            setGeneralNotification('');
            setOtpBtnDisabled(false);
        } else {
            generalNotofication.style.display = 'block';
            setGeneralNotification('* Required');
            setOtpBtnDisabled(true);
        }
        return allFieldsAreValid;
    }


    function clearForm() {

        //dispatch(clear());
    }

    function loginLogout() {
        if (auth.isAuthenticated) {
            clearForm();
            sessionStorage['realmId'] = process.env.CORE_KC_REALM_NAME || 'wezesha-elimu';
        }
        auth.isAuthenticated ? auth.logout() : keycloak.realm.indexOf('wezesha-') == -1 ? auth.login({
            scope: 'openid',
            redirectUri: `${process.env.BASE_URL}/dashboard`,
            prompt: 'login',
            action: 'tologin',
            maxAge: '100000',
            loginHint: emailAddress,
            idpHint: 'idpHint',
            cordovaOptions: null
        }) : console.log('Logging into wezesha-elimu realms not allwed');
    }

    function stopCallerInterval() {
        clearInterval(loaderInterval);
    }

    async function toggleInstituteName() {
        const institutionNameField = document.getElementById('institutionName_');
        const selectedUserType = document.getElementById('selectedUserType').value;
        setSelectedUserType(selectedUserType);
        if (selectedUserType === 'An Institution') {
            institutionNameField.style.display = 'flex';
            document.getElementById('instAdminField').style.display = 'flex';
            document.getElementById('institutionName').setAttribute('required', '');
        } else {
            institutionNameField.style.display = 'none';
            document.getElementById('instAdminField').style.display = 'none';
            document.getElementById('institutionName').value = '';
            document.getElementById('institutionName').removeAttribute('required');
        }

    }

    return (
        <div className="signupPage">

            <SignupProgressLoader
                setRegIsComplete={setRegIsComplete}
                setupInProgress={setupInProgress}
                setSetupInProgress={setSetupInProgress}
                submitInProgress={submitInProgress}
                auth={auth}
                displayLoader={displayLoader}
                regIsComplete={regIsComplete}
                clearForm={clearForm}
                setDisplayLoader={setDisplayLoader}
                loginLogout={loginLogout}
                stopCallerInterval={stopCallerInterval}
                message={loaderMsg}
                display={displayLoader}
                loader='spin' />

            <div className="signUpForm">
                <div className="signUpHeader">
                    Sign Up
                </div>
                <div className="FormField">
                    <label htmlFor="userType">I am ...</label><br />
                    <span className="fieldError"></span>
                    <input id="selectedUserType" required onClick={(e) => { e.target.value = ''; }} disabled={false} className="form-control" onChange={async (e) => { setUserType(e.target.value); await toggleInstituteName(); await validateAllFields(); toggleInstituteName(); }} list="userType" name="userTypee" />
                    <datalist contentEditable={false} id="userType">
                        <option value="An Institution">An Institution</option>
                        <option value="A Sponsor"></option>
                        <option value="A Learner"></option>
                    </datalist>
                </div>

                <div id="institutionName_" className="initialHide paddingup">
                    <div className="FormField">
                        <span className="fieldError" htmlFor="institutionName"></span>
                        <input required onChange={async (e) => { setInstitutionName(e.target.value); await validateAllFields(); }} id="institutionName" name="institutionName" className="form-control" type="text" placeholder="Institution Name" ></input>
                    </div>
                </div>
                <div id="instAdminField" className="instAdminField">&nbsp;&nbsp;&nbsp;Institution Admin</div>
                <div className="FormField">
                    <span className="fieldError" htmlFor="firstName"></span>
                    <input required onChange={async (e) => { setFirstName(e.target.value); await validateAllFields(); }} id="firstName" name="firstName" className="form-control" type="text" placeholder="First Name" ></input>
                </div>
                <div className="FormField">
                    <span className="fieldError"></span>
                    <input onChange={async (e) => { setMiddleName(e.target.value); await validateAllFields(); }} name="middleName" className="form-control" type="text" placeholder="Middle Name" ></input>
                </div>
                <div className="FormField">
                    <span className="fieldError"></span>
                    <input required onChange={async (e) => { setLastName(e.target.value); await validateAllFields(); }} name="lastName" className="form-control" type="text" placeholder="Last Name" ></input>
                </div>
                <div className="FormField">
                    <span className="fieldError"></span>
                    <input required onKeyUp={async (e) => { await validateAllFields(); }} onChange={async (e) => { setEmailAddress(e.target.value); await validateAllFields(); }} name="emailAddress" className="form-control" type="email" placeholder="Email Address" ></input>
                </div>
                <div className="FormField">
                    <span className="fieldError"></span>
                    <input required onChange={async (e) => { setPhoneNumber(e.target.value); await validateAllFields(); }} name="phoneNumber" className="form-control" type="number" placeholder="Phone Number" minLength={5}></input>
                </div>
                <div className="FormField">
                    <span className="otpSect" >
                        <a id="otpLink" className="otpLink" disabled={otpBtnDisabled || countDownSeconds > 0} onClick={generateOtp}>Send Email OTP</a>
                        <br />
                        <i id="sendAgain" className="sendAgain">Send again in {countDownSeconds}s</i>
                    </span>
                    <input required disabled={otpBtnDisabled || otpNounce === ''} onChange={(e) => { setOtp(e.target.value); validateOtp(e.target.value);; validateAllFields(); }} style={{ width: '50%' }} name="otp" className="form-control" type="text" placeholder="OTP" ></input>
                </div>
                <div className="FormField">
                    <div id="generalNotofication" className="generalNotofication">{generalNotification}</div>
                </div>
                <div className="FormField">
                    <button disabled={signupButtonIsDisabled} onClick={signup} className="signUpBtn">Sign Up</button>
                </div>
            </div>
            {showLoader && <PageLoader />}
        </div>
    );
}