import React from 'react';
import {useState} from 'react';
import Institution from '../../../../../api/models/Institution';


export default function InstitutionForm() {

    const [institutionName, setInstitutionName] = useState('');
    const [institutionType, setInstitutionType] = useState('primary-school');
    const [country, setCountry] = useState('kenya');
    const [yearOfEstablishement, setYearOfEstablishement] = useState('2024');

    async function post(event) {
        event.preventDefault();
        const institution  = await Institution.create({
            institutionName : institutionName,
            institutionType : institutionType,
            country : country,
            yearOfEstablishement : yearOfEstablishement
        }).fetch();
        
        //_constructor(institutionName, institutionType, country, yearOfEstablishement);
        console.log('institution->', institution);
    }
    return (
        <div style={{margin: 'auto', width: '100%', border : '1px solid #b4b4cf', borderRadius: 5, padding: 5, boxSizing: "border-box"}}>

           <div style={{padding: '5px',margin: '5px',color: 'white', backgroundColor: 'rgb(162 162 194)', textAlign:'center', fontWeight:400, fontWeight:'bold'}}>
                    Institution
                </div>
            
            <form style={{marginTop: 0,width: '100%', display: 'flex', flexWrap : 'wrap',boxSizing: 'border-box', paddingLeft: '5px', paddingRight: '5px'}}>

                <div style={{marginBottom: '1px', marginTop: '1px', padding:'5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width:'100%'}}>
                    <label style={{border: '1px solid rgba(199, 209, 255, 0.192)',fontWeight: 300, width: '50%'}}>Name</label>
                    <input  onChange={(e) => setInstitutionName(e.target.value)} value={institutionName} name='institutionName' style={{border: '1px solid rgba(199, 209, 255, 0.192)',width: '50%'}} ></input>
                </div>


                <div style={{marginBottom: '1px', marginTop: '1px', padding:'5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width:'100%'}}>

                    <label style={{border: '1px solid rgba(199, 209, 255, 0.192)',fontWeight: 300, width: '50%'}}>Type</label>
                    <select value={institutionType} onChange={(e)=>setInstitutionType(e.target.options.value)}  style={{border: '1px solid rgba(199, 209, 255, 0.192)',width: '50%'}} >
                        <option defaultValue value="primary-school">Primary School</option>
                        <option value="secondary-school" >Secondary School</option>
                        <option value="university" >University</option>
                        <option value="community-college" >Community College</option>
                    </select>
                </div>
                <div style={{marginBottom: '1px', marginTop: '1px', padding:'5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width:'100%'}}>
                    <label style={{border: '1px solid rgba(199, 209, 255, 0.192)',fontWeight: 300, width: '50%'}}>Country</label>
                    <select value={country} onChange={(e)=>setCountry(e.target.options.value)}   style={{border: '1px solid rgba(199, 209, 255, 0.192)',width: '50%'}}  >
                        <option defaultValue value="kenya">Kenya</option>
                        <option value="uganda">Uganda</option>
                        <option value="tanzania" >Tanzania</option>
                        <option value="south-sudan">South Sudan</option> 
                        <option value="rwanda">Rwanda</option> 
                        <option value="burundi">Burundi</option> 
                        <option value="drc">DRC</option> 
                    </select>
                </div>
                <div style={{marginBottom: '1px', marginTop: '1px', padding:'5px', boxSizing: 'border-box', display: 'flex', flex: '0 0 auto', width:'100%'}}>
                    <label style={{border: '1px solid rgba(199, 209, 255, 0.192)',fontWeight: 300, width: '50%'}}>Year of Establishment</label>
                    <input value={yearOfEstablishement} onChange={(e)=>setYearOfEstablishement(e.target.value)} style={{border: '1px solid rgba(199, 209, 255, 0.192)',width: '50%'}} ></input>
                </div>
                <div style={{flexDirection:'column',flexFlow: 'column', margin: 'auto', padding:'5px', boxSizing: 'border-box', 'flexDirection': 'row-reverse',display: 'flex', /*flex: '0 0 auto',*/ width:'50%'}}>
                    <button onClick={(e) => { return post(e) }}  style={{fontWeight: 300}}>Save</button>
                </div>
            </form>

        </div>
    )
}