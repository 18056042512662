import Keycloak from 'keycloak-js'; 

const keycloak = new Keycloak({
  url: process.env.CORE_KC_BASE_URL ,
  realm: process.env.CORE_KC_REALM_NAME ,
  clientId: process.env.KC_WEB_CLIENT_ID ,
  pkceMethod: process.env.KC_WEB_CLIENT_PKCE_METHOD ,
  KeycloakResponseType: process.env.KC_RESPONSE_TYPE ,
  onLoad: process.env.KC_ONLOAD ,
  checkLoginIframe: process.env.KC_CHECK_LOGIN_IFRAME 
});
export default keycloak;